import React, { useEffect, useRef, useState } from "react";
import { imageUrl, url } from "../../services/Urls";
import { GetListById, icons, save, sortingTable } from "../../components/ShareComp";
import ReactTableShare from "../../components/ReactTableShare";
import CreateDoctor from "./CreateDoctor";
import Select from "react-select";
import AssignModel from "../Users/AssignModel";
import { UseFormValidations } from "../../validations/UseFormValidation";
import CreateAvailability from "./CreateAvailability";
import { NavLink } from "react-router-dom";
import User from "../../assets/images/user.jpg"
const DoctorList = () => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [model, setModel] = useState(false);
  const [update, setUpdate] = useState([]);
  const { data, handleChangeSearch ,handleImageUpload,setValues} = UseFormValidations({});
  const [hospitalAll, setHospitalAll] = useState([]);
  const fileInputRef = useRef(null);
    const [obj,setobj]=useState({})
  
  const GetAllFscility = async () => {
    let res = await GetListById(url.getAllHospital, { id: 0 });
    res?.map((v) => {
      v["label"] = v?.hospitalName;
      v["value"] = v?.hospitalId;
    });
   
    setHospitalAll(res);
  };
  const getAllScan = async () => {
    let res = await GetListById(url.getAllDoctors, { id: 0 });
    res?.map((v) => {
      v?.userHospitals?.map((c) => {
        c["label"] = c.hospitalName;
        c["value"] = c.hospitalId;
      });
      v?.userSpecialities?.map((c) => {
        c["label"] = c.specialityName;
        c["value"] = c.specialityId;
      });
    });
    setList(res);
  };
  const uploadImage=async(v)=>{
    
    const formData=new FormData()
    formData.append("id",obj?.userId)
    formData.append("image",v)
    let res=await save(url.userUpload,formData)
    if(res?.status){
      setUpdate(res)
      setValues({})
    }
  }
  const submit = async (obj) => {
    const res = await save(url?.saveDoctors, obj);
    setUpdate(res);
    setModel(false);
  };
  const openModal = (name, value) => {
    setModel({
      [name]: value,
    });
  };

  const assignHospital = async (obj) => {
    let res = await save(url?.assignHospital, obj);
   
    setUpdate(res);
    setModel(false);
  };
  const assignspeciality = async (obj) => {
    let res = await save(url?.assignspeciality, obj);
    setUpdate(res);
    setModel(false);
  };
  const columns = [
   
    {
      name: "Name",
      selector: (v) => <div  onClick={()=>handleButtonClick(v)}>
        <div className="d-flex flex-wrap gap-2 align-items-center ptr">
        <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }} // Hide the input field
        onChange={handleImageUpload("image")}
        accept="image/*" // Restrict to image files
      />
          <img src={v?.image?imageUrl+'Users/'+v?.image: User} className="img-fluid rounded border" style={{height:"30px",width:"30px"}}/>
           <span>{v.userName}</span>
           </div>
        
      </div>,
      sortable: true,
      sortFunction: (a, b) => sortingTable(a, b, "userName"),

      width: "12rem",
    },
    { name: "Email", selector: (v) => v?.email, sortable: true, width: "12rem" },
    { name: "Gender", selector: (v) => v?.gender, sortable: true, width: "8rem"},

    { name: "phone", selector: (v) => v?.phone&&"+91"+" "+v?.phone, sortable: true, width: "10rem"  },
   
    {
      name: "Hospital",
      selector: (v) => v?.userHospitals?.map((v) => <li>{v?.hospitalName}</li>),
      // sortable: true,
      width: "13rem",
    },
    {
      name: "Speciality",
      selector: (v) =>
        v?.userSpecialities?.map((v) => <li  className="text-wrap">{v?.specialityName}</li>),
      // sortable: true,
      width: "12rem",
    },
    {
      name: "Action",
      selector: (v) => (
        <div className="ptr d-flex gap-2">
          <span onClick={() => openModal("user", v)}>{icons?.edit}</span>
          <span onClick={() => openModal("assign", v)}>
            {icons?.assignHospital}
          </span>
          <span onClick={() => openModal("speciality", v)}>
            {icons?.assignSpecility}
          </span>
        </div>
      ),
      
      width: "",
    },
  ];

  const handleChang = () => (e) => {
    setSearch(e.target.value);
  };
  const handleButtonClick = (v) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setobj(v) 
    }
  };
  const listsearch = () => {
    return (
      <div className=" d-flex flex-wrap gap-1" style={{ width: "100%" }}>
        <div className="col">
          <input
            type="search"
            className="form-control search-control search-bg "
            value={search != "" ? search : ""}
            onChange={handleChang()}
            placeholder="Search Doctors..."
          />
        </div>
        {/* <div className="col">
          <Select
            value={data?.hospitalId}
            isClearable={true}
            options={hospitalAll}
            placeholder={"Select Hospital"}
            className="text-start"
            name="facility"
            onChange={handleChangeSearch("hospitalId")}
          />
        </div> */}
      </div>
    );
  };

  const SaveAvailabilityDoc=async(obj)=>{
    let res=await save(url?.doctorAvailability,obj)
    setModel(false)
  }
  useEffect(()=>{
    if(data?.image?.name){
      uploadImage(data?.image)
     
    }
   
  },[data?.image])
  useEffect(() => {
    GetAllFscility();
  }, []);
  useEffect(() => {
    getAllScan();
  }, [update]);
  
  return (
    <div>
      <div className="d-flex flex-wrap py-1">
        <div className="col-md-6 px-2 heading_list ">Doctors</div>
        <div className="col-md-6 d-flex flex-wrap gap-2 justify-content-end">
        <NavLink to={"/xlcarez/doctor/availability"}
            className="btn bg_btn col-auto text-white d-flex gap-2 align-items-center none"
            
          >
            {icons.add}
            <span className="color2">View Availability</span>
          </NavLink >
        <div
            className="btn bg_btn col-auto text-white d-flex gap-2 align-items-center"
            onClick={() => openModal("availability", true)}
          >
            {icons.add}
            <span className="color2">Add Availability</span>
          </div>
          <div
            className="btn bg_btn col-auto text-white d-flex gap-2 align-items-center"
            onClick={() => openModal("user", true)}
          >
            {icons.add}
            <span className="color2">Add Doctor</span>
          </div>
         
        </div>
      </div>
      <div className="py-2">
        <ReactTableShare
          dataTable={list}
          columns={columns || []}
          search1={listsearch}
          search={search}
        />
      </div>
      {model?.user && (
        <CreateDoctor
          show={model?.user}
          onHide={() => setModel(false)}
          submit={submit}
        />
      )}
      {(model?.assign?.userId || model.speciality?.userId) && (
        <AssignModel
          show={model?.assign || model.speciality}
          onHide={() => setModel(false)}
          hospitalAll={hospitalAll}
          speciality={model?.speciality ? true : false}
          submit={model?.assign ? assignHospital : assignspeciality}
        />
      )}
      {model.availability&&<CreateAvailability show={model?.availability} hospitalAll={hospitalAll} onHide={() => setModel(false)} submit={SaveAvailabilityDoc}/>}
    </div>
  );
};

export default DoctorList;
