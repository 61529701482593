import React, { useEffect } from 'react'
import { UseFormValidations } from '../../validations/UseFormValidation'
import { Modal } from 'react-bootstrap'
import Select from "react-select"
const AddScanLimit = (props) => {
    const submit=()=>{
        const formdata= new FormData();
        formdata.append("Id",0)
        formdata.append("Title",data?.title)
        formdata.append("Upload",data?.upload)
        
        props?.submit(formdata)
    }
    const {data,errors,handleChange,handleNumberChange,handleAlphabetChange,handleSubmit,setValues,handleImageUpload}=UseFormValidations({
      initialValues: {
        title: "",
        upload: "",
       
      },
      validationSchema: {
        title: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        upload: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
       
      },
        submit:submit
    })
    const returnValue=(key)=>{
        return data?.[key]&&data?.[key]?data?.[key]:""
    }
    const ErrorValue=(key)=>{
    return errors?.[key]&&errors?.[key]?" form-control border border-danger":"form-control"
    }
    // useEffect(()=>{
    //     if (props?.show?.hospitalId){
    //         setValues(props?.show)
    //     }
    // },[props?.show?.hospitalId])
    console?.log(data,"check")
  return (
    <Modal  {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header >
      <Modal.Title>Upload</Modal.Title>
    </Modal.Header>
   <form onSubmit={handleSubmit}>
   <Modal.Body>
     <div className='row'>
<div>
    <label>Title<span className='text-danger'>*</span></label>
    <input className={ErrorValue("title")} placeholder='Title' value={returnValue("title")} onChange={handleAlphabetChange("title")}/>

</div>
<div className='py-2'>
    <label>Upload<span className='text-danger'>*</span></label>
    <input type='file' className={ErrorValue("upload")}   onChange={handleImageUpload("upload")}/>

</div>



     </div>
    </Modal.Body>
    <Modal.Footer>
      <button className='btn border px-5' onClick={()=>props?.onHide()} >
        Close
      </button>
      <button className='bg_btn btn text-white px-5'>
        Save 
      </button>
    </Modal.Footer>
   </form>
  </Modal>
  )
}

export default AddScanLimit