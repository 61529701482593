import React, { useEffect, useRef, useState } from 'react'
import { GetListById, icons, save, sortingTable } from '../../components/ShareComp'
import { imageUrl, url } from '../../services/Urls'
import ReactTableShare from '../../components/ReactTableShare'
import { UseFormValidations } from '../../validations/UseFormValidation'
import AddScanLimit from './AddScanLimit'
const Scanlistuser = () => {
    const [list,setList]=useState([])
    const [search,setSearch]=useState("")
    const [model,setModel]=useState(false)
    const [update,setUpdate]=useState([])
    const fileInputRef = useRef(null);
    const [obj,setobj]=useState({})
    const {data,handleImageUpload,setValues}=UseFormValidations({})
    const getAllScan=async()=>{
      let res=await GetListById(url.scan.noscanalluser,{id:0})
      setList(res)
    }
    const submit=async(obj)=>{
      const res=await save(url?.upload.saveFile,obj);
         setUpdate(res)
         setModel(false)
    }
   const deleteFile=async(fileId)=>{
    const formdata= new FormData();
        formdata.append("Id",fileId)
    let res=await save(url.upload.saveFile,formdata)
    setUpdate(res)
   }
   const columns = [
    {
      name: "Name",
      selector: (v) => (
        <div 
        // onClick={() => handleButtonClick(v)}
        >
          <div className="d-flex flex-wrap gap-2 align-items-center ptr ">
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }} // Hide the input field
              onChange={handleImageUpload("image")}
              accept="image/*" // Restrict to image files
            />
            <img
            //     src={v?.image ? imageUrl + "Users/" + v?.image : User}
              className="img-fluid rounded border"
              style={{ height: "30px", width: "30px" }}
            />
            <span>{v.userName}</span>
          </div>
        </div>
      ),
      sortable: true,
      sortFunction:( a,b)=>sortingTable(a,b,"userName"),
      width: "14rem",
    },
    {
      name: "Email",
      selector: (v) => <div className="text-wrap">{v?.email}</div>,
      sortable: true,
      width: "12rem",
      sortFunction:( a,b)=>sortingTable(a,b,"email"),
    },
    { name: "Gender", selector: (v) => v?.gender, sortable: true, width: "" },

    {
      name: "phone",
      selector: (v) => v?.phone && "+91" + " " + v?.phone,
      sortable: true,
      width: "",
    },

    {
      name: "Hospital",
      selector: (v) => v?.userHospitals?.map((v) => <li>{v?.hospitalName}</li>),
      sortable: true,
      width: "",
    },
    {
      name: "Action",
      selector: (v) => (
        <div className="ptr d-flex gap-2">
          
        </div>
      ),
      width: "",
    },
  ];
    
    const handleChang = () =>(e)=> {
      setSearch(e.target.value)
    }

    const listsearch = () => {
      return (<div className=' d-flex flex-wrap ' style={{ width: "100%" }}>
        
       <div className='col-md-12'>
       <input type="search"  className="form-control search-control search-bg "
          value={search!=""?search:""} onChange={handleChang()} placeholder="Search..." />
       </div>
        
        
      </div>)
    }
  
   
    useEffect(()=>{getAllScan()},[update])
    return (
      <div >
         <div className='d-flex flex-wrap py-1'>
        <div className='col-md-6 px-2 heading_list '>Scan Limit</div>
        <div className='col-md-6 d-flex flex-wrap gap-2 justify-content-end'>
        
        <div className='btn bg_btn col-auto text-white d-flex gap-2 align-items-center' onClick={()=>setModel(!model)}>
          {icons.add}<span className='color2'>Add Scan</span>
        </div>
        </div>
        </div>
      <div className='py-2'><ReactTableShare dataTable={list||[]} columns={columns||[]} search1={listsearch} search={search}/></div>
        { model&&<AddScanLimit show={model} onHide={()=>setModel(false)} submit={submit}/>}
      </div>
    )
}

export default Scanlistuser