import React, { useEffect, useState } from 'react'
import { FaCartPlus, FaHospitalUser, FaIdCard } from 'react-icons/fa'
import { GrSchedules } from 'react-icons/gr'
import { LuUsers } from 'react-icons/lu'
import { GetListById } from '../../components/ShareComp'
import { url } from '../../services/Urls'
import AppointmentList from "../Appointment/AppointmentList"
import moment from 'moment'
import { IoDocumentText, IoWallet, IoWalletOutline } from "react-icons/io5";
import { AiOutlineGlobal } from 'react-icons/ai'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { BiClinic } from 'react-icons/bi'
import { FcList } from 'react-icons/fc'
import { CiCircleList } from 'react-icons/ci'

const AdminDashbord = () => {
  const [hospital,setHospital]=useState([]);
  const [doctor,setDoctor]=useState([]);
  const [app,setApp]=useState([]);
  const [count,setCount]=useState({})
  const list=[
    { label:"Total Appointments",count:count?.appointmentCount||0,icon: <CiCircleList  color='#fff' size={18}/>},
    { label:"Total Hospitals",count:count?.hospitalCount||0,icon: <BiClinic color='#fff' size={18}/>},
    { label:"Total Users",count:count?.patientCount||0,icon:<HiOutlineUserGroup color='#fff' size={18} />},
    { label:"New Subscribed",color:"#F6F6F6", list:[
      {label:"Annually",value:count?.premiumCount,},
      {label:"Quarterly",value:count?.eliteCount,},
      {label:"Monthly",value:count?.standardCount,}, 
    ],icon:<FaIdCard size={18} />},
    // { label:"Total Sale",color:"#D0E9BC",count:"90",icon:<FaCartPlus size={18} />},
  ]
  const getsubscription=async()=>{
    let res=await GetListById(url.getAllHospital,{id:0});
    res?.map(v=>{
      v["label"]=v?.hospitalName
      v["value"]=v?.hospitalId
    })
    setHospital(res)
  }
  const getIndividual=async()=>{
    let res=await GetListById(url.getAllDoctors,{id:0});
    res?.map(v=>{
      v["label"]=v?.userName
      v["value"]=v?.userId
    })
    setDoctor(res)
  }
  const AllAppointment=async()=>{
    let res=await GetListById(url.getAllAppointment,{id:0})
    res?.map(v=>{
      v["type"]=v?.teleConsultation=="yes"?"Tele Consultation":"Hospital Visit"
      v["preferredDate"]=moment(v?.preferredDate).format("DD-MM-YYYY")
    })
    setApp(res)
  }
  const AllCount=async()=>{
    let res=await GetListById(url?.getAllCount)
    setCount(res[0])
    console?.log(res,"res")
  }
  useEffect(()=>{
    AllCount()
    getsubscription()
    getIndividual()
    AllAppointment()
  },[])
  return (
   <div className='px-2 '>
    
    <div className='d-flex flex-wrap py-2 '>
       {
        list?.map(v=>{
          return (
            <div className=' col px-1' >
            <div className='  card text-start p-2 'style={{  zIndex:"-1"}}>
{!v?.list&&<div className='d-flex flex-wrap p-1'>
<div className='col-md-10 color3' >
 <div style={{color:"#A0AEC0"}}> {v?.label}</div>
  <div className='color3 mt-2 w-25 text-start bg-white  ' >{v.count}</div>
  
  </div>
  <div 
  className='col-md-1 py-2 mt-1 text-center d-flex justify-content-center align-items-center' 
  style={{ background: "#668ebd", borderRadius: "12px" ,width:"16%"}}>
  {v?.icon}
</div>

   
 
</div>}
<div className='d-flex flex-wrap justify-content-around '>
{
    v?.list?.map(sub=>{
     return  <div className='py-1' >
     <div className={'px-1 col '} style={{color:"#A0AEC0"}}>{sub?.label}</div>
     <div className={`${sub.color} px-1 color3 text-center mt-1`}>{sub?.value}</div>
     </div>
    })
  } 
</div>
            </div>
          </div>
          )
        })
       }
    </div>
    <AppointmentList list={app} doctor={doctor} hospital={hospital}  />
   
   </div>
  )
}

export default AdminDashbord