

import * as XLSX from 'xlsx';
import ReactTable from "react-data-table-component"
import { customStyles, GetList, GetListById, icons, save } from './ShareComp';
import { url } from '../services/Urls';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTableShare from './ReactTableShare';

import Wellness from "../assets/img/wellness.png";
import HbA1c from "../assets/img/hbac.png";
import Hemoglobin from "../assets/img/hemoglobin.png";
import pulseRate from "../assets/img/pulserate.png";
import Respiration from "../assets/img/respirate.png";
import bprisk from "../assets/img/bprisk.png";
import Cardiorisk from "../assets/img/cardioRisk.png";
import Oxygen from "../assets/img/oxygen.png";
import Diabetes from "../assets/img/diabetic.png";
import axios from 'axios';


const exportToExcel = (list) => {
  const tableHeaders = ["Sno", "Name", "Age","Weight", "Depressed", "Tobacco", "Alcohol", "Wellness", "Hb", "HbA1c", "Diabetes", "RR", "BP", "SaO2", "Pulse", "Cardio"];
  
 const wsData = [
      
    tableHeaders,   
    ...list.map((patient, index) => [
      index + 1,
      patient.FirstName,
      patient.Age,
      patient?.Weight,
      patient.Depressed,
      patient.Smoking,
      patient.Drinking,
      patient.wellnessIndex,
      patient.hemoglobin,
      patient.hemoglobinA1C,
      patient.DiabitiesRiskScore,
      patient.respirationRate,
      patient.bloodPressure,
      patient.oxygenSaturation,
      patient.pulseRate,
      patient.Cardio,
    ])
  ];

  const ws = XLSX.utils.aoa_to_sheet(wsData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "PatientsData");
  XLSX.writeFile(wb, "PatientsData.xlsx");
};

const PHM = () => {
  const [list,setList]=useState([])
  const [search,setSearch]=useState("")
  const [resData, setResData] = useState({});
  const [resTableValues, setResTableValues] = useState([]);
  const [selectedX, setSelectedX] = useState("");
  const [filterData, setFilterData] = useState([]);
  let column=[
    {
      name:"S.no",
      selector:(v)=>v?.index,
         width:"6rem",
         sortable:true
    },
    {
      name:"Name",
      selector:(v)=><div className='text-wrap'>{v?.FirstName+" "+v?.LastName}</div>,
         width:"10rem",
         sortable:true
    },
    {
      name:"Age",
      selector:(v)=>v?.Age,
         width:"7rem",
         sortable:true
    },
    {
      name:"Weight",
      selector:(v)=>v?.Weight?v?.Weight:"-",
         width:"7rem",
         sortable:true
    },
    {
      name:"Depressed",
      selector:(v)=><div className='text-wrap'>{v?.depressed?v?.depressed:"-"}</div>,
         width:"9rem",
         sortable:true
    },
    {
      name:"Tobacco",
      selector:(v)=>v?.Smoking&&v?.Smoking!='null'?v?.Smoking:"-",
         width:"7rem",
         sortable:true
    },
   
    {
      name:"Alcohol",
      selector:(v)=>v?.Drinking?v?.Drinking:"-",
         width:"7rem",
         sortable:true
    },
    
     {
      name:"Wellness",
      selector:(v)=><div  className='p-2 rounded ' title={selectedX === "wellnessLevel" ? v?.WellnessIndexCategory === "High" ? "High Risk" : "Moderate Risk" : ""} style={{backgroundColor: selectedX === "wellnessLevel" ? v?.WellnessIndexCategory === "High" ? "#C12F0F" : "Orange" : ""}}>{v?.wellnessLevel?v?.wellnessLevel:"-"}</div> ,
         width:"8rem",
         sortable:true
    },
    {
      name:"Hb",
      selector:(v)=><div className='p-2 rounded '  title={selectedX === "Hemoglobin_Risk" ? v?.Hemoglobin_Risk === "High risk" ? "High Risk" : "Low Risk" : ""} style={{backgroundColor: selectedX === "Hemoglobin_Risk" ? v?.Hemoglobin_Risk === "High risk" ? "#C12F0F" : "Orange" : ""}}>{v?.hemoglobin?v?.hemoglobin:"-"}</div>,
         width:"5rem",
         sortable:true
    },
    {
      name:"HbA1c",
      selector:(v)=><div  className='p-2 rounded ' title={selectedX === "HbA1cCategory" ? v?.HbA1cCategory === "Diabetes" ? "Diabetes" : "Prediabetes" : ""} style={{backgroundColor: selectedX === "HbA1cCategory" ? v?.HbA1cCategory === "Diabetes" ? "#C12F0F" : "Orange" : ""}}>{v?.hemoglobinA1C?v?.hemoglobinA1C:"-"}</div>,
         width:"6rem",
         sortable:true
    },
    {
      name:"Diabities",
      selector:(v)=> <div  className='p-2 rounded '  title={selectedX === "Diabetes_Risk" ? v?.Diabetes_Risk === "Very HIGH RISK" ? "Very High Risk" : "Moderate Risk" : ""} style={{backgroundColor: selectedX === "Diabetes_Risk" ? v?.Diabetes_Risk === "Very HIGH RISK" ? "#C12F0F" : "Orange" : ""}}>{v?.DiabitiesRiskScore?v?.DiabitiesRiskScore:"-"}</div>,
         width:"7rem",
         sortable:true
    },
    {
      name:"RR",
      selector:(v)=><div  className='p-2 rounded ' title={selectedX === "Respiration Rate Category" ? v?.RespirationRateCategory === "High" ? "High Risk" : "Moderate Risk" : ""} style={{backgroundColor: selectedX === "Respiration Rate Category" ? v?.RespirationRateCategory === "High" ? "#C12F0F" : "Orange" : ""}}>{v?.respirationRate?v?.respirationRate:"-"}</div>,
         width:"5rem",
         sortable:true
    },{
      name:"BP",
      selector:(v)=><div  className='p-2 rounded ' title={selectedX === "BP Category" ? v?.BPCategory === "Hypertensive Crisis" ? "Hypertensive Crisis" : "Hypertension Stage 2" : ""} style={{backgroundColor: selectedX === "BP Category" ? v?.BPCategory === "Hypertensive Crisis" ? "#C12F0F" : "Orange" : ""}}>{v?.bloodPressure?v?.bloodPressure:"-"}</div>,
         width:"7rem",
         sortable:true
    },
    {
      name:"SaO2",
      selector:(v)=><div  className='p-2 rounded '>{v?.oxygenSaturation?v?.oxygenSaturation:"-"}</div>,
         width:"6rem",
         sortable:true
    },
    {
      name:"Pulse",
      selector:(v)=><div  className='p-2 rounded ' title={selectedX === "Pulse Rate Category" ? v?.PulseRateCategory === "High" ? "High Risk" : "Low Risk" : ""} style={{backgroundColor: selectedX === "Pulse Rate Category" ? v?.PulseRateCategory === "High" ? "#C12F0F" : "Orange" : ""}}>{v?.pulseRate?v?.pulseRate:"-"}</div>,
         width:"6rem",
         sortable:true
    },
    {
      name:"Cardio",
      selector:(v)=><div  className='p-2 rounded ' title={selectedX === "Cardio_risk" ? v?.Cardio_risk === "High" ? "High Risk" : "Intermediate Risk" : ""} style={{backgroundColor: selectedX === "Cardio_risk" ? v?.Cardio_risk === "High" ? "#C12F0F" : "Orange" : ""}}>{v?.Cardio?v?.Cardio:"-"}</div>,
         width:"7rem",
         sortable:true
    },
  ]
  const data = {
    main: {
      name: "Total Suspected",
      count: resData?.Total_Suspected_risk || 0,
      img: Wellness,
    },
    mentalHealth: 18,
    categories: [
      {
        name: "Wellness Index",
        count: resData?.WellnessIndexRisk || 0,
        img: Wellness,
      },
      { name: "HbA1c", count: resData?.HbA1c || 0, img: HbA1c },
      { name: "Hemoglobin (Hb)", count: resData?.hemoglobin || 0, img: Hemoglobin },
      { name: "Pulse Rate (Pulse)", count: resData?.pulseRate || 0, img: pulseRate },
      {
        name: "Respiration Rate (RR)",
        count: resData?.RespirationRate || 0,
        img: Respiration,
      },
      {
        name: "Oxygen Saturation (SaO2)",
        count: resData?.OxygenSaturation || 0,
        img: Oxygen,
      },
      {
        name: "Blood Pressure (BP)",
        count: resData?.BloodpressureRisk || 0,
        img: bprisk,
      },
      {
        name: "Diabetes",
        count: resData?.Diabetes_Risk || 0,
        img: Diabetes,
      },
      {
        name: "Cardio",
        count: resData?.Cardio_risk || 0,
        img: Cardiorisk,
      },
    ],
  };
  
  const handleSelectChange = (event) => {
    setSelectedX(event.target.value);
  };
  const getAllOrgUsersList=async()=>{
    let res=await GetList(url?.phm.getAllOrgUser+2,{})
    if(res?.data){
      setResData(res?.data[0]||{})
      res?.data[1]?.map((v,i)=>{v["index"]=i+1})
      setList(res?.data[1]||[])
    }
   

  }
  useEffect(()=>{
    
    getAllOrgUsersList()
  },[])
  const handleChang = () =>(e)=> {
    setSearch(e.target.value)
  }
  useEffect(() => {
    const filterByCategory = () => {
      if (!selectedX) {
        setFilterData(list);
      } else {
        const filtered = list?.filter((e) => {
          switch (selectedX) {
            case "HbA1cCategory":
              return e?.HbA1cCategory === "Prediabetes" || e?.HbA1cCategory === "Diabetes";
            case "wellnessLevel":
              return e?.WellnessIndexCategory === "High" || e?.WellnessIndexCategory === "Moderate Risk";
            case "Hemoglobin_Risk":
              return e?.Hemoglobin_Risk === "Low risk" || e?.Hemoglobin_Risk === "High risk";
            case "Pulse Rate Category":
              return e?.PulseRateCategory === "High" || e?.PulseRateCategory === "Low";
            case "Respiration Rate Category":
              return e?.RespirationRateCategory === "High" || e?.RespirationRateCategory === "Low";
            case "SaturationCategory":
              return e?.SaturationCategory === "Mild Hypoxia" || e?.SaturationCategory === "Moderate to Severe Hypoxia";
            case "BP Category":
              return e?.BPCategory === "Hypertension Stage 2" || e?.BPCategory === "Hypertensive Crisis";
            case "Diabetes_Risk":
              return e?.Diabetes_Risk === "Very HIGH RISK" || e?.Diabetes_Risk === "MODERATE RISK";
            case "Cardio_risk":
              return e?.Cardio_risk === "Intermediate" || e?.Cardio_risk === "High";
            default:
              return true;
          }
        });
        setFilterData(filtered);
      }
    };
    filterByCategory();
  }, [selectedX, list]);
  const listsearch = () => {
    return (<div className=' d-flex flex-wrap gap-1' style={{ width: "100%" }}>
     
     <div className='col'>
     <input type="search"  className="form-control search-control search-bg "
        value={search!=""?search:""} onChange={handleChang()} placeholder="Search..." />
     </div>
     
      
    </div>)
  }
  return (
    <div className='px-2'>       
      <div className="summary ">
                <div className="col-md-2  justify-content-center align-items-center">
                  <div className="rounded mb-3 d-flex justify-content-center align-items-center" style={{ width: "180px", height: "75px" }}>
                    <h3 className="col-md-12 d-flex justify-content-between align-items-center text-secondary">Health Risk(Suspected)</h3>
                  </div>
                  <div className="border rounded mb-3 d-flex justify-content-center align-items-center" style={{ width: "180px", height: "170px" }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <span className="fw-bold p-1 fs-1">{data?.main?.count}</span>
                          <img className="p-1" src={data?.main?.img} height={35} />
                        </div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="p-1 fw-semi-bold text-start color3">{data?.main?.name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 d-flex justify-content-start ">
                  <div className="col-md-12 d-flex justify-content-start flex-wrap ">
                    {data.categories.map((category, index) => (
                     <div className='col-md-3 px-2'>
                       <div className="mb-2 border rounded col-md-12" key={index} style={{ height: "70px" }}>
                        <div className="col-md-12 d-flex justify-content-between">
                          <img className="p-1 " src={category?.img} height={35} />
                          <span className="fw-bold p-1 fs-5 color3">{category.count}</span>
                        </div>
                        <div className="col-md-12 d-flex justify-content-between">
                          <span className="p-1 fw-semi-bold color3">{category.name}</span>
                        </div>
                      </div>
                     </div>
                    ))}
                  </div>
                </div>
              </div>
      <div className=' d-flex flex-wrap gap-2 justify-content-end align-items-center'>
        <div className='col-md-3'>
          <label>Select Feature</label>
              <select  className="form-control" onChange={handleSelectChange} value={selectedX}>
                <option value="">Select All</option>
                <option value="wellnessLevel">Wellness Index Risk</option>
                <option value="HbA1cCategory">HbA1c Risk</option>
                <option value="Hemoglobin_Risk">Hemoglobin Risk</option>
                <option value="Pulse Rate Category">Pulse Rate Risk</option>
                <option value="Respiration Rate Category">Respiration Rate Risk</option>
                <option value="SaturationCategory">Oxygen Saturation Risk</option>
                <option value="BP Category">BP Risk</option>
                <option value="Diabetes_Risk">Diabetes Risk</option>
                <option value="Cardio_risk">Cardio Risk</option>
              </select>
              </div>
              <div className='col-md-2 bg-success text-white border h-100 btn mt-4'onClick={()=>exportToExcel(list)}> {icons.excelicon} Download In Excel
              </div>
       </div>
      
      <div className='my-2'>
        <ReactTableShare customStyles={customStyles} dataTable={filterData||[]}columns={column||[]}search1={listsearch} search={search} />
        </div>
    </div>
  )
}

export default PHM
