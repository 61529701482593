import React, { useEffect } from 'react'
import { UseFormValidations } from '../../validations/UseFormValidation'
import { Modal } from 'react-bootstrap'
import Select from "react-select"
const AddScan = (props) => {
    const submit=()=>{
        let body={
            userId:data?.userId,
            scansLimit:data?.scansLimit
        }
        props?.submit(body)
    }
    const {data,errors,handleNumberChange,handleSubmit,setValues}=UseFormValidations({
      initialValues: {
        userName: "",
        scansLimit: "",
       
      },
      validationSchema: {
        userName: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        scansLimit: {
          required: {
            value: true,
            message: "Please enter number",
          },
        },
       
      },
        submit:submit
    })
    const returnValue=(key)=>{
        return data?.[key]&&data?.[key]?data?.[key]:""
    }
    const ErrorValue=(key)=>{
    return errors?.[key]&&errors?.[key]?" form-control border border-danger p-2":"form-control p-2"
    }
    useEffect(()=>{
        if (props?.show?.userId){
            setValues(props?.show)
        }
    },[props?.show?.userId])
    console?.log(data,"check")
  return (
    <Modal  {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header >
      <Modal.Title>Add Scan Limit</Modal.Title>
    </Modal.Header>
   <form onSubmit={handleSubmit}>
   <Modal.Body>
     <div className='row'>
<div>
    <label>Patient Name<span className='text-danger px-1'>*</span></label>
    <input className={ErrorValue("title")} placeholder='Title' value={returnValue("userName")} disabled/>

</div>
<div className='py-2'>
    <label>Scans Limit<span className='text-danger px-1'>*</span></label>
    <input  className={ErrorValue("scansLimit")} placeholder='Scans Limit'  onChange={handleNumberChange("scansLimit")}/>

</div>



     </div>
    </Modal.Body>
    <Modal.Footer>
      <button className='btn border px-5' onClick={()=>props?.onHide()} >
        Close
      </button>
      <button className='bg_btn btn text-white px-5'>
        Save 
      </button>
    </Modal.Footer>
   </form>
  </Modal>
  )
}

export default AddScan