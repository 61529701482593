import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import Navbar from '../components/NavBar'
import AdminDashbord from '../Modules/Dashboard/AdminDashbord'
import AppRouting from '../Modules/Appointment/Routing'
import SubscribRouting from "../Modules/Subscribtion/SubscribRouting"
import ScanRouting from '../Modules/ScanPackage/ScanRouting'
import Dashboard from '../components/Dashboard'
import Login from '../components/Login'
import FacilityRouting from '../Modules/Facility/FacilityRouting'
import SpecialityRouting from '../Modules/Speciality/SpecialityRouting'
import DoctorRouting from '../Modules/Doctor/DoctorRouting'
import UserRouting from '../Modules/Users/UserRouting'
import PatientRouting from '../Modules/Patient/PatientRouting'
import Policy from '../components/Policy'
import PrivateRouting from './PrivateRouting'
import RoutingTransaction from '../Modules/Transaction/RoutingTransaction'
import PHM from '../components/PHM'
import Demo from '../components/Demo'
import ChangePassword from '../components/ChangePassword'
import RoutingOrg from '../Modules/organization/RoutingOrg'
import Routingupload from '../Modules/upload/Routingupload'
import RoutingScan from '../Modules/addscan/RoutingScan'
// import ModifyDashboard from '../components/ModifyDashboard'

const LandingPage =lazy(() => import("../components/LandingPage"))
const ModifyDashboard =lazy(() => import("../components/ModifyDashboard"))
const RoutingConfig = () => {
  return (

    <Routes>
        
        <Route path='/policy' element={<Policy/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/' element={<LandingPage/>}></Route>
       
        <Route  path='dashboard'  element={<PrivateRouting><ModifyDashboard/></PrivateRouting>}></Route>
        
       
        <Route path='/forgot_password/:userId' element={<ChangePassword/>}></Route>
        <Route path="/xlcarez/*" element={<Navbar/>}>
<Route path='dashboard'element={<PrivateRouting><AdminDashbord/></PrivateRouting>}></Route>
<Route path='appointment/*'element={<PrivateRouting><AppRouting/></PrivateRouting>}></Route>
<Route path='subscribtion/*'element={<PrivateRouting><SubscribRouting/></PrivateRouting>}></Route>
<Route path='scanpackage/*'element={<PrivateRouting><ScanRouting/></PrivateRouting>}></Route>
<Route path='hospital/*'element={<PrivateRouting><FacilityRouting/></PrivateRouting>}></Route>
<Route path='speciality/*'element={<PrivateRouting><SpecialityRouting/></PrivateRouting>}></Route>
<Route path='doctor/*'element={<PrivateRouting><DoctorRouting/></PrivateRouting>}></Route>
<Route path='users/*'element={<PrivateRouting><UserRouting/></PrivateRouting>}></Route>
<Route path='patient/*'element={<PrivateRouting><PatientRouting/></PrivateRouting>}></Route>
<Route path='transaction/*'element={<PrivateRouting><RoutingTransaction/></PrivateRouting>}></Route>
<Route path='phm/all'element={<PrivateRouting><PHM/></PrivateRouting>}></Route>
<Route path='organization/*'element={<PrivateRouting><RoutingOrg/></PrivateRouting>}></Route>
<Route path='demo'element={<PrivateRouting><Demo/></PrivateRouting>}></Route>
<Route path='upload/*'element={<PrivateRouting><Routingupload/></PrivateRouting>}></Route>
<Route path='scan/*'element={<PrivateRouting><RoutingScan/></PrivateRouting>}></Route>

        </Route>
        
    </Routes>
  )
}

export default RoutingConfig