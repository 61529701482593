import React, { useEffect, useRef, useState } from 'react'
import { GetListById, icons, save } from '../../components/ShareComp'
import { imageUrl, url } from '../../services/Urls'
import Select from "react-select"
import ReactTableShare from '../../components/ReactTableShare'

import hospital from "../../assets/images/hospital.webp"
import { UseFormValidations } from '../../validations/UseFormValidation'
import Createupload from './Createupload'
const UploadList = () => {
    const [list,setList]=useState([])
    const [search,setSearch]=useState("")
    const [model,setModel]=useState(false)
    const [update,setUpdate]=useState([])
    const fileInputRef = useRef(null);
    const [obj,setobj]=useState({})
    const {data,handleImageUpload,setValues}=UseFormValidations({})
    const getAllScan=async()=>{
      let res=await GetListById(url.upload.getfile,{id:0})
     
      setList(res)
    }
    const submit=async(obj)=>{
      const res=await save(url?.upload.saveFile,obj);
         setUpdate(res)
         setModel(false)
    }
   const deleteFile=async(fileId)=>{
    const formdata= new FormData();
        formdata.append("Id",fileId)
    let res=await save(url.upload.saveFile,formdata)
    setUpdate(res)
   }
    const columns = [
      {name:"Id",cell:(v)=>v?.id,width:"6rem"},
   
     {
        name: "file",
        cell: (v) => <a href={imageUrl+`/Docs/${v?.uploadedFileName}`}target='_blank' download>
            {v?.fileExtension==".pdf"?icons?.pdf:icons.img}
            <span className='px-1'>{v?.uploadedFileName}</span>
            </a>,
     },
      {
        name: "Title",
        cell: (v) => <div className="text-wrap">{v?.title}</div>,
        sortable: true,
        selector: (v) => v?.title,
        
      },
     
      {
        name: "Action",
        selector: (v) => (
          <div  >
           {/* <span className="ptr" onClick={() => setModel(v)}> {icons?.edit}</span> */}
           <span title='Delete File' className="ptr" onClick={()=>deleteFile(v?.id)}> {icons?.delete}</span>
          </div>
        ),
      
      },
    ];
    
    const handleChang = () =>(e)=> {
      setSearch(e.target.value)
    }

    const listsearch = () => {
      return (<div className=' d-flex flex-wrap ' style={{ width: "100%" }}>
        
       <div className='col-md-12'>
       <input type="search"  className="form-control search-control search-bg "
          value={search!=""?search:""} onChange={handleChang()} placeholder="Search..." />
       </div>
        
        
      </div>)
    }
  
   
    useEffect(()=>{getAllScan()},[update])
    return (
      <div >
         <div className='d-flex flex-wrap py-1'>
        <div className='col-md-6 px-2 heading_list '>Uploads</div>
        <div className='col-md-6 d-flex flex-wrap gap-2 justify-content-end'>
        
        <div className='btn bg_btn col-auto text-white d-flex gap-2 align-items-center' onClick={()=>setModel(!model)}>
          {icons.add}<span className='color2'>Upload</span>
        </div>
        </div>
        </div>
      <div className='py-2'><ReactTableShare dataTable={list||[]} columns={columns||[]} search1={listsearch} search={search}/></div>
        { model&&<Createupload show={model} onHide={()=>setModel(false)} submit={submit}/>}
      </div>
    )
}

export default UploadList