import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Scanlistuser from './Scanlistuser'

const RoutingScan = () => {
  return (
    <Routes>
        <Route path='all'element={<Scanlistuser/>}></Route>
    </Routes>
  )
}

export default RoutingScan