import React, { useEffect, useRef, useState } from 'react'
import { GetListById, icons, save } from '../../components/ShareComp'
import { imageUrl, url } from '../../services/Urls'
import Select from "react-select"
import ReactTableShare from '../../components/ReactTableShare'
import CreateFacility from './CreateFacility'
import hospital from "../../assets/images/hospital.webp"
import { UseFormValidations } from '../../validations/UseFormValidation'
const FacilityList = () => {
    const [list,setList]=useState([])
    const [search,setSearch]=useState("")
    const [model,setModel]=useState(false)
    const [update,setUpdate]=useState([])
    const fileInputRef = useRef(null);
    const [obj,setobj]=useState({})
    const {data,handleImageUpload,setValues}=UseFormValidations({})
    const getAllScan=async()=>{
      let res=await GetListById(url.getAllHospital,{id:0})
      res&&res?.map(v=>{
        v["services"]=v?.services&&JSON.parse(v.services)
      })
      setList(res)
    }
    const submit=async(obj)=>{
      const res=await save(url?.saveHospital,obj);
         setUpdate(res)
         setModel(false)
    }
    const handleButtonClick = (v) => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
        setobj(v) 
      }
    };
   
    const columns = [
      {
        name: "Logo",
        selector: (v) => (
          <div onClick={() => handleButtonClick(v)}>
            <img
              src={v?.image ? imageUrl + "Hospital/" + v?.image : hospital}
              className="img-fluid p-1 text-end ptr"
              style={{ borderRadius: "15px" }}
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }} // Hide the input field
              onChange={handleImageUpload("image")}
              accept="image/*" // Restrict to image files
            />
          </div>
        ),
        width: "5rem",
      },
      {
        name: "Hospital Name",
        cell: (v) => <div className="text-wrap">{v?.hospitalName}</div>,
        sortable: true,
        selector: (v) => v?.hospitalName,
        width: "20rem",
      },
      {
        name: "short Name",
        cell: (v) => <div className="text-wrap">{v?.shortName}</div>,
        sortable: true,
        selector: (v) => v?.shortName,
        width: "14rem",
      },
      {
        name: "Address",
        cell: (v) => <div className="text-wrap">{v?.address}</div>,
        sortable: true,
        selector: (v) => v?.address,
  
        width: "20rem",
      },
      {
        name: "Action",
        selector: (v) => (
          <div className="ptr" onClick={() => setModel(v)}>
            {icons?.edit}
          </div>
        ),
        width: "",
      },
    ];
    
    const handleChang = () =>(e)=> {
      setSearch(e.target.value)
    }
  const uploadImage=async(v)=>{
    
    const formData=new FormData()
    formData.append("id",obj?.hospitalId)
    formData.append("image",v)
    let res=await save(url.hospitalUpload,formData)
   
    if(res?.status){
      setUpdate(res)
      setValues({})
    }
   
  }
    const listsearch = () => {
      return (<div className=' d-flex flex-wrap ' style={{ width: "100%" }}>
        
       <div className='col-md-12'>
       <input type="search"  className="form-control search-control search-bg "
          value={search!=""?search:""} onChange={handleChang()} placeholder="Search Hospital..." />
       </div>
        
        
      </div>)
    }
    useEffect(()=>{
      if(data?.image?.name){
        uploadImage(data?.image)
       
      }
     
    },[data?.image])
   
    useEffect(()=>{getAllScan()},[update])
    return (
      <div >
         <div className='d-flex flex-wrap py-1'>
        <div className='col-md-6 px-2 heading_list '>Hospital</div>
        <div className='col-md-6 d-flex flex-wrap gap-2 justify-content-end'>
        
        <div className='btn bg_btn col-auto text-white d-flex gap-2 align-items-center' onClick={()=>setModel(!model)}>
          {icons.add}<span className='color2'>Add Hospital</span>
        </div>
        </div>
        </div>
      <div className='py-2'><ReactTableShare dataTable={list||[]} columns={columns||[]} search1={listsearch} search={search}/></div>
        { model&&<CreateFacility show={model} onHide={()=>setModel(false)} submit={submit}/>}
      </div>
    )
}

export default FacilityList