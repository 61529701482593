import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UploadList from './UploadList'

const Routingupload = () => {
  return (
    <Routes>
        <Route path='all'element={<UploadList/>}></Route>
    </Routes>
  )
}

export default Routingupload